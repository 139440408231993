import React from "react"
import * as s from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { BiPhoneCall } from "react-icons/bi"
import { HiOutlineLocationMarker } from "react-icons/hi"
import Layout from "../../Layout"
import details from "./_details"

const ContactUsPage = () => {
  return (
    <Layout>
      <Seo title="Contact us" />
      <Breadcrumbs title={"Contact us"} />

      <section>
        <div className="container">
          <div className="row justify-content-center">
            {details.map((location, idx) => {
              const { iframeUrl, title, address, phone } = location || {}
              return (
                <div className="col-lg-6 col-xl-4 mt-5" key={idx}>
                  <div className={s.contact_box}>
                    <iframe
                      src={iframeUrl}
                      frameBorder="0"
                      title={title}
                      allowFullScreen=""
                      aria-hidden="false"
                      className={s.map}
                      loading="lazy"
                    />

                    <p className={s.c_detail}>
                      <div>
                        <HiOutlineLocationMarker />
                      </div>{" "}
                      <span>{address}</span>
                    </p>
                    <p className={s.c_detail}>
                      <div>
                        <BiPhoneCall />
                      </div>{" "}
                      <a href={`tel:${phone}`}>{phone}</a>
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default ContactUsPage
